import React from 'react'
import styled from 'styled-components'
import { Box, Button, colors } from 'js-components'
import {
  Section,
  WrapperBackground,
  WrapperSolid,
} from '../../components/Wrapper'
import { IconRow } from '../../components/IconRow'
import { Photo } from '../../components/PhotoCarousel'
import {
  PageTitle,
  PageTitle2,
  PageParagraph,
  PageRegular,
} from '../../components/PageTitle'
import Default from '../../components/Layouts/Default'

import DesktopSalesforce from '../images/desktopSalesforce.png'
import Remote from '../images/remote.png'

const AboutPage = () => (
  <Default>
    <WrapperBackground
      color={colors.white}
      background="businessScene2"
      gradientType="light">
      <Section
        flexDirection="row"
        py={['6rem', '7rem']}
        background={colors.trulyDark}>
        <Box width={[1, 2 / 3]}>
          <PageTitle
            as="h2"
            color={colors.accentLavender}
            bold
            mb="1.5rem"
            center>
            Careers at Truly
          </PageTitle>
          <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" center>
            Make a real impact at a rapidly growing startup. We’re backed by top
            VCs including Greylock, Index, BoldStart, and ENIAC. Hundreds of
            customers rely on Truly every day, including MapBox, Zocdoc,
            Signpost, and Managed By Q.
          </PageParagraph>
          <Photo photo="simon" />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://truly.pinpointhq.com">
            <Button
              size="extraLarge"
              round
              color={colors.accentLavender}
              value="See Open Positions"
            />
          </a>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid color={colors.accentPurple}>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem']}>
        <Box width={[1 / 2, 1 / 3]} p="2rem">
          <WidthImg src={DesktopSalesforce} />
        </Box>
        <Box width={[1, 2 / 3]}>
          <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem">
            We’re Disrupting The Way People Make Calls
          </PageTitle2>
          <PageParagraph as="p" color={colors.white} mb="1.5rem">
            We built Truly from the ground up to address the thousands of VOIP,
            Reporting, and Integration challenges the most demanding
            organizations deal with daily.
          </PageParagraph>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color={colors.trulyDark}>
      <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
        <Box width={[1, 2 / 3]} mb="16pt">
          <WidthImg src={Remote} />
        </Box>
        <Box width={[1, 3 / 4]}>
          <PageTitle2 as="h2" color={colors.white} bold mb="1.5rem" center>
            A Distributed Team
          </PageTitle2>
          <PageParagraph as="p" color={colors.white} mb="1.5rem">
            Our team is everywhere, from home offices in Seattle to coffee shops
            in Ohio. Because a significant portion of our team is remote, we've
            built a culture based on unconditional transparency, clear
            communication, and mutual trust. And between regular meetups at our
            HQ in San Francisco, you'll get to know the team through virtual
            hangouts, slack, and team stand-ups.
          </PageParagraph>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="sf" gradientType="light">
      <Section
        flexDirection={['column', 'row']}
        mt="0"
        m={['1rem', '2rem', '5rem']}>
        <Box width={[1, 1]} mb="16pt">
          <PageTitle center color={colors.accentLavender}>
            Benefits
          </PageTitle>
        </Box>
        <Box width={[1, 1 / 2]}>
          <IconRow
            icon="location"
            caption="Work From Anywhere"
            text="Truly is a distributed team."
          />
          <IconRow
            icon="health"
            caption="Stay Healthy"
            text="Truly offers full medical, dental, and vision for you and your family."
          />
          <IconRow
            icon="vacation"
            caption="Unplug"
            text="With flexible paid time off."
          />
        </Box>
        <Box width={[1, 1 / 2]}>
          <IconRow
            icon="books"
            caption="Learning"
            text="Quarterly learning budget so you can get smarter."
          />
          <IconRow
            icon="tools"
            caption="Pro Tools"
            text="Brand new MacBook Pro and all the tools you need to really shine."
          />
          <IconRow
            icon="city"
            caption="Hang out"
            text="Meet up with your remote team face-to-face at least once annually."
          />
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid color={colors.accentPurple}>
      <Section
        flexDirection={['column', 'row']}
        mt="0"
        m={['1rem', '2rem', '5rem']}>
        <Box width={[1, 1]} mb="16pt">
          <PageTitle center color={colors.white}>
            Our Commitment to You
          </PageTitle>
        </Box>
        <Box width={[1, 1]}>
          <IconRow
            icon="thoughtful"
            color={colors.white}
            caption=""
            text="We aim to be thoughtful of your time, flexible with ours, and quick to get back to you."
          />
          <IconRow
            icon="goals"
            color={colors.white}
            caption=""
            text="Our priority is making sure we can help you achieve your career goals, whether it’s becoming an expert in your field or starting your own business."
          />
          <IconRow
            icon="communication"
            color={colors.white}
            caption=""
            text="We’ll always be honest about the opportunity, never over-sell, and make sure you’re constantly learning."
          />
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="bridge" gradientType="trulyDark">
      <Section
        flexDirection={['column', 'row']}
        mt="0"
        m={['1rem', '2rem', '5rem']}>
        <Box width={[1, 1]} mb="16pt">
          <PageTitle center color={colors.white}>
            Our Recruitment Process
          </PageTitle>
        </Box>
        <Box width={[1, 1]}>
          <Table>
            <IconRow
              icon="recruitmentIntro"
              caption="Introduction"
              text="Quick chat with recruiting."
            />
            <DividerLine />
            <IconRow
              icon="recruitmentPhone"
              caption="Phone Call"
              text="More in-depth conversation with your potential manager."
            />
            <DividerLine />
            <IconRow
              icon="recruitmentExercise"
              caption="Exercise"
              text="To give you a real look at the work you’d be doing."
            />
            <DividerLine />
            <IconRow
              icon="recruitmentSuperday"
              caption="Superday"
              text="Meeting a few people you’d work closely with (either in person or via Zoom)."
            />
            <DividerLine />
            <IconRow
              icon="recruitmentOffer"
              caption="Offer"
              text="Welcome to the team!"
            />
          </Table>
        </Box>
      </Section>
    </WrapperBackground>

    <WrapperSolid color={colors.accentPurple}>
      <Section
        flexDirection={['column', 'row']}
        mt="0"
        m={['1rem', '2rem', '5rem']}>
        <Box width={[1, 1]} mb="16pt">
          <PageTitle center color={colors.white} mb="16pt">
            Join Us
          </PageTitle>
          <PageParagraph as="p" color={colors.white} mb="1.5rem" center>
            Make a real impact at a rapidly growing startup. We’re backed by top
            VCs including Greylock, Index, BoldStart, and ENIAC. Hundreds of
            customers rely on Truly every day, including MapBox, Zocdoc,
            Signpost, and Managed By Q.
          </PageParagraph>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://truly.pinpointhq.com">
            <Button
              size="extraLarge"
              round
              color={colors.accentLavender}
              value="See Open Positions"
            />
          </a>
        </Box>
      </Section>
    </WrapperSolid>
  </Default>
)

export default AboutPage

const Table = styled.div`
  background-color: white;
  padding: 8pt 0;
  border-radius: 8pt;
`

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbd0d8;
`

const MediumLink = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16pt;
  background-color: ${colors.accentPurple};
  border-radius: 8pt;
  opacity: 0.6;
  transition: 0.3s all;

  :hover {
    opacity: 1;
    transition: 0.3s all;
    cursor: pointer;
  }
`

const WidthImg = styled.img`
  width: 100%;
`
