import React from 'react'
import styled from 'styled-components'
import { colors } from 'js-components'
import { PageLabel, PageRegular } from '../PageTitle'

import Books from './Icons/books.svg'
import City from './Icons/city.svg'
import Health from './Icons/health.svg'
import Location from './Icons/location.svg'
import Tools from './Icons/tools.svg'
import Vacation from './Icons/vacation.svg'
import Thoughtful from './Icons/thoughtful.svg'
import Communication from './Icons/communication.svg'
import Goals from './Icons/goals.svg'
import RecruitmentExercise from './Icons/recruitmentExercise.svg'
import RecruitmentIntro from './Icons/recruitmentIntro.svg'
import RecruitmentOffer from './Icons/recruitmentOffer.svg'
import RecruitmentPhone from './Icons/recruitmentPhone.svg'
import RecruitmentSuperday from './Icons/recruitmentSuperday.svg'
import Question from './Icons/questionBullet.svg'

const getLogo = logo => {
  switch (logo) {
    default:
    case 'books':
      return Books
    case 'city':
      return City
    case 'health':
      return Health
    case 'location':
      return Location
    case 'tools':
      return Tools
    case 'vacation':
      return Vacation
    case 'thoughtful':
      return Thoughtful
    case 'communication':
      return Communication
    case 'recruitmentExercise':
      return RecruitmentExercise
    case 'recruitmentIntro':
      return RecruitmentIntro
    case 'recruitmentOffer':
      return RecruitmentOffer
    case 'recruitmentPhone':
      return RecruitmentPhone
    case 'recruitmentSuperday':
      return RecruitmentSuperday
    case 'goals':
      return Goals
    case 'question':
      return Question
  }
}

function Background(props) {
  return (
    <IconRow>
      <Icon src={getLogo(props.icon)} />
      <TextWrapper>
        <PageLabel color={props.color} mb="4pt">
          {props.caption}
        </PageLabel>
        <PageRegular color={props.color}>{props.text}</PageRegular>
      </TextWrapper>
    </IconRow>
  )
}

Background.defaultProps = {
  gradientType: 'light',
  backgroundSize: 'fill',
  backgroundRepeat: 'none',
  caption: 'Caption',
  color: colors.trulyDark,
  text: 'Lorem Ipsum',
  videoLink: 'https://player.vimeo.com/video/264697195?autoplay=1',
  background: 'hero',
  opacity: 1,
}

export default Background

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16pt;
  text-align: left;
  justify-content: left;
  align-items: center;
`

const Icon = styled.img`
  width: 60pt;
  height: 60pt;
  padding: 8pt;
  margin-right: 8pt;
`

const TextWrapper = styled.div`
  width: 100%;
`
